import { render, staticRenderFns } from "./DeliveryMethodToggle.vue?vue&type=template&id=3798d1f8"
import script from "./DeliveryMethodToggle.vue?vue&type=script&lang=js"
export * from "./DeliveryMethodToggle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Toggle: require('/home/ploi/app.tribehappiness.com-deploy/app.tribehappiness.com/01042025_084311/components/Toggle.vue').default})
